<template>
  <v-card @click="$emit('click', {})"
          outlined
          :loading="loading"
          :hover="true"
          class="d-flex flex-column fill-height"
          style="user-select: none; overflow: hidden;"
          @dragstart.prevent="() => false"
  >
    <v-container fluid>
      <v-row class="flex-nowrap mb-5">
        <v-col cols="8" class="d-flex flex-column flex-grow-1 flex-shrink-0">
          <h3 class="text-truncate">{{ item.company.name }}</h3>
          <div class="mt-auto">
            <div class="text-truncate body-2">{{ item.phone }}</div>
            <div class="text-truncate body-2">{{ item.email }}</div>
          </div>
        </v-col>
        <v-col class="ml-auto flex-grow-0 flex-shrink-0">
          <Avatar :size="82" :subject="item"></Avatar>
        </v-col>
      </v-row>
      <v-row class="flex-nowrap justify-center">
        <v-col class="flex-grow-1">
          <div v-if="item.company.manager" class="text-truncate mt-1 body-2"><span>Manager: </span><span>{{ item.company.manager.name }}</span></div>
          <div v-else class="text-truncate mt-1 body-2"><span>Manager not assigned</span></div>
        </v-col>
        <v-col class="flex-grow-0 flex-shrink-0">
          <v-btn
              :to="{name: 'order.index', params: {'client_uuid': item.uuid }}"
              :disabled="!item.company || !item.company.manager" width="92"
              small depressed block
          >Orders</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Avatar from "./Avatar";

export default {
  name: "ClientCard",
  components: {Avatar},
  props: {
    item: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  }
}
</script>